import React from 'react';
import styled from '@emotion/styled';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const Root = styled('div')({
  backgroundColor: '#f5f5f5',
  padding: 16,
  '@media only screen and (max-width: 425px)': {
    padding: 0
  },
  '.content': {
    maxWidth: 700,
    margin: 'auto',
    backgroundColor: '#ffffff',
    padding: 16,
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.3)'
  },
  ol: {
    paddingInlineStart: 24,
  },
  p: {
    textAlign: 'justify',
  }
});

class PrivacyPolicyFb extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={createMuiTheme()}>
        <Root>
          <div className={'content'}>
            <Typography variant={'h4'} component={'h1'} paragraph>
              Web Site Terms and Conditions of Use
            </Typography>

            <Typography variant={'h6'} gutterBottom>
              1. Terms
            </Typography>

            <Typography paragraph>
              By accessing this web site, you are agreeing to be bound by these
              web site Terms and Conditions of Use, applicable laws and
              regulations and their compliance. If you disagree with any of the
              stated terms and conditions, you are prohibited from using or
              accessing this site. The materials contained in this site are
              secured by relevant copyright and trade mark law.
            </Typography>

            <Typography variant={'h6'}>2. Use License</Typography>

            <ol type="a">
              <li>
                <Typography>
                  Permission is allowed to temporarily download one duplicate of
                  the materials (data or programming) on Muze Innovation's site
                  for individual and non-business use only. This is the just a
                  permit of license and not an exchange of title, and under this
                  permit you may not:
                </Typography>
                <ol type="i">
                  <li>
                    <Typography>modify or copy the materials;</Typography>
                  </li>
                  <li>
                    <Typography>
                      use the materials for any commercial use , or for any
                      public presentation (business or non-business);{' '}
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      attempt to decompile or rebuild any product or material
                      contained on Muze Innovation's site;
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      remove any copyright or other restrictive documentations
                      from the materials; or
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      transfer the materials to someone else or even "mirror"
                      the materials on other server.
                    </Typography>
                  </li>
                </ol>
              </li>
              <li>
                <Typography>
                  This permit might consequently be terminated if you disregard
                  any of these confinements and may be ended by Muze Innovation
                  whenever deemed. After permit termination or when your viewing
                  permit is terminated, you must destroy any downloaded
                  materials in your ownership whether in electronic or printed
                  form.
                </Typography>
              </li>
            </ol>

            <Typography variant={'h6'} gutterBottom>
              3. Disclaimer
            </Typography>

            <ol type="a">
              <li>
                <Typography>
                  The materials on Muze Innovation's site are given "as is".
                  Muze Innovation makes no guarantees, communicated or
                  suggested, and thus renounces and nullifies every single other
                  warranties, including without impediment, inferred guarantees
                  or states of merchantability, fitness for a specific reason,
                  or non-encroachment of licensed property or other infringement
                  of rights. Further, Muze Innovation does not warrant or make
                  any representations concerning the precision, likely results,
                  or unwavering quality of the utilization of the materials on
                  its Internet site or generally identifying with such materials
                  or on any destinations connected to this website.
                </Typography>
              </li>
            </ol>

            <Typography variant={'h6'} gutterBottom>
              4. Constraints
            </Typography>

            <Typography paragraph>
              In no occasion should Muze Innovation or its suppliers subject for
              any harms (counting, without constraint, harms for loss of
              information or benefit, or because of business interference,)
              emerging out of the utilization or powerlessness to utilize the
              materials on Muze Innovation's Internet webpage, regardless of the
              possibility that Muze Innovation or a Muze Innovation approved
              agent has been told orally or in written of the likelihood of such
              harm. Since a few purviews don't permit constraints on inferred
              guarantees, or impediments of obligation for weighty or
              coincidental harms, these confinements may not make a difference
              to you.
            </Typography>

            <Typography variant={'h6'} gutterBottom>
              5. Amendments and Errata
            </Typography>

            <Typography paragraph>
              The materials showing up on Muze Innovation's site could
              incorporate typographical, or photographic mistakes. Muze
              Innovation does not warrant that any of the materials on its site
              are exact, finished, or current. Muze Innovation may roll out
              improvements to the materials contained on its site whenever
              without notification. Muze Innovation does not, then again, make
              any dedication to update the materials.
            </Typography>

            <Typography variant={'h6'} gutterBottom>
              6. Links
            </Typography>

            <Typography paragraph>
              Muze Innovation has not checked on the majority of the websites or
              links connected to its website and is not in charge of the
              substance of any such connected webpage. The incorporation of any
              connection does not infer support by Muze Innovation of the site.
              Utilization of any such connected site is at the user's own risk.
            </Typography>

            <Typography variant={'h6'} gutterBottom>
              7. Site Terms of Use Modifications
            </Typography>

            <Typography paragraph>
              Muze Innovation may update these terms of utilization for its
              website whenever without notification. By utilizing this site you
              are consenting to be bound by the then current form of these Terms
              and Conditions of Use.
            </Typography>

            <Typography variant={'h6'} gutterBottom>
              8. Governing Law
            </Typography>

            <Typography paragraph>
              Any case identifying with Muze Innovation's site should be
              administered by the laws of the country of Thailand Muze
              Innovation State without respect to its contention of law
              provisions.
            </Typography>

            <Typography>
              General Terms and Conditions applicable to Use of a Web Site.
            </Typography>

            <Typography variant={'h6'} gutterBottom>
              Privacy Policy
            </Typography>

            <Typography paragraph>
              Your privacy is critical to us. Likewise, we have built up this
              Policy with the end goal you should see how we gather, utilize,
              impart and reveal and make utilization of individual data. The
              following blueprints our privacy policy.
            </Typography>

            <ul>
              <li>
                <Typography>
                  Before or at the time of collecting personal information, we
                  will identify the purposes for which information is being
                  collected.
                </Typography>
              </li>
              <li>
                <Typography>
                  We will gather and utilization of individual data singularly
                  with the target of satisfying those reasons indicated by us
                  and for other good purposes, unless we get the assent of the
                  individual concerned or as required by law.
                </Typography>
              </li>
              <li>
                <Typography>
                  We will just hold individual data the length of essential for
                  the satisfaction of those reasons.
                </Typography>
              </li>
              <li>
                <Typography>
                  We will gather individual data by legal and reasonable means
                  and, where fitting, with the information or assent of the
                  individual concerned.
                </Typography>
              </li>
              <li>
                <Typography>
                  Personal information ought to be important to the reasons for
                  which it is to be utilized, and, to the degree essential for
                  those reasons, ought to be exact, finished, and updated.
                </Typography>
              </li>
              <li>
                <Typography>
                  We will protect individual data by security shields against
                  misfortune or burglary, and also unapproved access,
                  divulgence, duplicating, use or alteration.
                </Typography>
              </li>
              <li>
                <Typography>
                  We will promptly provide customers with access to our policies
                  and procedures for the administration of individual data.
                </Typography>
              </li>
            </ul>

            <Typography>
              We are focused on leading our business as per these standards with
              a specific end goal to guarantee that the privacy of individual
              data is secure and maintained.
            </Typography>
          </div>
        </Root>
      </MuiThemeProvider>
    );
  }
}

export default PrivacyPolicyFb;
